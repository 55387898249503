import React, { useEffect, useRef, useState } from 'react';
import { useSprings, useSpring, a } from '@react-spring/web';
import { useDrag } from 'react-use-gesture';

const data = [
  { title: 'Big Sup', background: '#754533', image: 'https://rawcdn.githack.com/dilums/interactive-slider-animation/7a45f511c3bcc809bacc73102083cfb342ea2670/public/0.jpg' },
  { title: 'Pikes Peak ', background: '#354046', image: 'https://rawcdn.githack.com/dilums/interactive-slider-animation/7a45f511c3bcc809bacc73102083cfb342ea2670/public/1.jpg' },
  { title: 'Tail of the Dragon', background: '#6F4623', image: 'https://rawcdn.githack.com/dilums/interactive-slider-animation/7a45f511c3bcc809bacc73102083cfb342ea2670/public/2.jpg' },
  { title: 'Route 26 Loop', background: '#333c45', image: 'https://rawcdn.githack.com/dilums/interactive-slider-animation/7a45f511c3bcc809bacc73102083cfb342ea2670/public/3.jpg' },
  { title: 'Route 348', background: '#2B3815', image: 'https://rawcdn.githack.com/dilums/interactive-slider-animation/7a45f511c3bcc809bacc73102083cfb342ea2670/public/4.jpg' },
];

const clamp = (val, min, max) => Math.min(max, Math.max(min, val));

const TOTAL_ITEMS = data.length;
const ITEM_GAP = 300;
const SLIDER_WIDTH = 248;
const SLIDER_HEIGHT = 417;
const TITLE_FONT_SIZE_DETAILS = 32;
const TITLE_FONT_SIZE_SLIDER = 18;

export default function Carousel({feedId, setShowIntroPanel, setCursor}) {
  const containerRef = useRef(null);

  const [posts, setPosts] = useState([])
  const [instaPlaying, setInstaPlaying] = useState(null)

  const mobile = window.innerWidth < 580;

  const [detailsView, setDetailsView] = useState(false);
  const [stylesSpring, stylesSpringApi] = useSpring(() => ({
    width: SLIDER_WIDTH,
    height: SLIDER_HEIGHT,
    fontSize: TITLE_FONT_SIZE_SLIDER,
    pt: 16,
    pl: 16,
    headerOpacity: 0,
    navDetailsOpacity: 0,
    navDetailsTop: 120,
  }));
/*
  const [backdropStyle, backdropStyleApi] = useSpring(() => ({
    background: data[1].background,
  }));
*/

function isMobile(){
  return (mobile)? false : true;
}



useEffect(() => {
  const controller = new AbortController()

  // Fetch feed, update state
  async function fetchFeed() {
    try {
      // Fetch and parse feed
      const rawFeed = await fetch(`https://feeds.behold.so/${feedId}`, {
      //const rawFeed = await fetch(`/assets/insta.json`, {
        signal: controller.signal,
      })

      // Pass http errors to the catch block
      if (!rawFeed.ok) {
        const errorMessage = await rawFeed.text()
        throw new Error(errorMessage)
      }

      // Parse JSON
      const feedJSON = await rawFeed.json()

      const allPosts = [...feedJSON.posts, [{
        msg: 'See more @waypastbooks on Instagram',
        url: 'https://www.instagram.com/waypastbooks/'
      }]]
      
      console.log('posts', feedJSON.posts)

      // Update state with fetched posts
      setPosts(feedJSON.posts)
     // setPosts(allPosts)

    } catch (err) {
      // Swallow AbortErrors, since they are intentional and expected
      if (err.name !== "AbortError") {
        console.error("Error:", err)
      }
    }
  }

  // Run
  fetchFeed()

  // Abort fetch if feedId changes or component is unmounted
  return () => {
    controller.abort()
  }
}, [feedId])

// Build post Els
const postEls = posts.map((post) => {
  // IMAGE or CAROUSEL_ALBUM
  let mediaEl = <img src={post.sizes.medium.mediaUrl} alt="" />

  // VIDEO
  if (post.mediaType === "VIDEO") {
    mediaEl = (
      <video
        poster={post.sizes.medium.mediaUrl}
        src={post.mediaUrl}
        muted={true}
        autoPlay={false}
        loop={true}
      ></video>
    )
  }

  return (
    <div className="insta-panel" key={"panel"+post.id}>
    <figure key={post.id}>
      {mediaEl}
     {/* <figcaption>{post.caption}</figcaption> */}
    </figure>
    </div>
  )
})

  const offset = (isMobile) ? 0 : 120;

 // console.log('sizes', posts)


  const selectedItem = useRef(0);
  const [springs, api] = useSprings(posts.length+1, (index) => ({
    x: ITEM_GAP * index - ITEM_GAP * selectedItem.current + offset,
  //  y: (index < posts.length) ? (posts[index].mediaType==='VIDEO') ? 0 : 100 : 0
  //  scale: (index === selectedItem.current ) ? 1.2 :  1
    // scale: 0.05 * index,
  }));

  //const cycle = (isMobile) ? TOTAL_ITEMS : TOTAL_ITEMS - 1;
  const cycle = (isMobile) ? posts.length+1 : posts.length;

  const activateNext = (x) => {
    selectedItem.current = clamp(
      selectedItem.current + (x > 0 ? -1 : 1),
      0,
      cycle
    );
  };

  const moveItems = (active, x) => {
    console.log('x', x);
    console.log('active', active);
    api.start((index) => {
      const root = ITEM_GAP * index - ITEM_GAP * selectedItem.current;
      return {
        x: active ? x + root + offset : root+ offset,
        
      };
    });
/*
    backdropStyleApi({
      background: data[selectedItem.current].background,
    });
    */
  };

  const onExpand = () => {
    const { width, height } = containerRef.current.getBoundingClientRect();
    api.start((index) => {
      const root = ITEM_GAP * index * 3 - ITEM_GAP * 3 * selectedItem.current;
      return {
        x: root,
      };
    });

    stylesSpringApi({
      width: width + 4,
      height: height + 4,
      fontSize: TITLE_FONT_SIZE_DETAILS,
      pt: 64,
      pl: 32,
      headerOpacity: 0.7,
      navDetailsOpacity: 1,
      navDetailsTop: 0,
    });
  };
  const onCollapse = () => {
    api.start((index) => {
      const root = ITEM_GAP * index - ITEM_GAP * selectedItem.current;
      return {
        x: root,
      };
    });

    stylesSpringApi({
      width: SLIDER_WIDTH,
      height: SLIDER_HEIGHT,
      fontSize: TITLE_FONT_SIZE_SLIDER,
      pt: 16,
      pl: 16,
      headerOpacity: 0,
      navDetailsOpacity: 0,
      navDetailsTop: 120,
    });
  };

  const bind = useDrag(({ active, movement: [x], tap, cancel }) => {
    if (!detailsView) {
      if (!tap) {
        if (active && Math.abs(x) > 50) {
          activateNext(x);
          cancel();
        }
        moveItems(active, x);
      } else {
        setDetailsView(true);
      }
    }
  });


  /*
  useEffect(() => {
    if (detailsView) {
      onExpand();
    } else {
      onCollapse();
    }
  }, [detailsView]);
*/
  return (
    <div className="layout insta-gallery" ref={containerRef}>

      <div 
      onMouseEnter={() => setCursor('', false)} 
      onMouseLeave={() => setCursor('', true)} 
       onClick={() => {
        if (selectedItem.current === 1 && mobile===false) setShowIntroPanel(true);
        //  alert('click');
        activateNext(100);
        moveItems(false, 100);
        console.log('selectedItem', selectedItem.current);
        //activateNext(3);
      //activateNext(3);
      }}
       // onMouseEnter={() => setCursor('See more videos...', false)} 
       // onMouseLeave={() => setCursor('', true)} 
        //onClick={() => menuClick('video') } 
        className="prev-btn roundButton arrowLeft" 
        style={{
            backgroundColor: '#9F65C2',
          
        }}/>
        
      <div 
      onMouseEnter={() => setCursor('', false)} 
      onMouseLeave={() => setCursor('', true)} 
       onClick={() => {
        if (selectedItem.current === 0 && mobile===false) setShowIntroPanel(false);
        //  alert('click');
        activateNext(-100);
        moveItems(false, 100);
        
        console.log('selectedItem', selectedItem.current);
       
        //activateNext(3);
      }}
       // onMouseEnter={() => setCursor('See more videos...', false)} 
       // onMouseLeave={() => setCursor('', true)} 
        //onClick={() => menuClick('video') } 
        className="next-btn roundButton arrowRight" 
        style={{
          backgroundColor: '#9F65C2',
        
      }}
      />

      <div className="content relative">
       {/* <a.div className="pl-10 pt-16 backdrop" style={backdropStyle}></a.div> */}
        <div className="absolute transform-self-center">
          <a.div
            className="relative disable-select "
            {...bind()}
            style={{ width: stylesSpring.width, height: stylesSpring.height }}
          >
            {springs.map((styles, index) => (
              <a.div
                className="absolute inner-height inset-0 flex  rounded flex-col justify-between bg-no-repeat bg-cover bg-center shadow"
                style={{
                  ...styles,
              //    backgroundImage: `url("${data[index].image}")`,
                }}
                key={index} // list is not dynamic
                
              >
                    {(index < posts.length) ?
             
                      <InstaPanel 
                        idx={index} 
                        posts={posts} 
                        setCursor={setCursor} 
                        instaPlaying={instaPlaying} 
                        setInstaPlaying={setInstaPlaying} 
                        />
                   :
                      <MessagePanel idx={index} setCursor={setCursor}/>
                    }
               
                 {/*postEls[index]*/}
              </a.div>
            ))}
          </a.div>
        </div>
      </div>
      
    </div>
  );
}

const InstaPanel = ({idx, posts,setCursor, instaPlaying, setInstaPlaying, ...props }) => {

  let mediaEl = <img src={posts[idx].sizes.medium.mediaUrl} alt="" />
  const [playing, setPlaying] = useState(false)
  const vidRef = useRef(null);

  useEffect(() => {

    if (idx !== instaPlaying){
      if( vidRef.current){
        vidRef.current.pause()
        vidRef.current.muted=true;
      }

    }

  },[instaPlaying])

  // VIDEO
  if (posts[idx].mediaType === "VIDEO") {
    mediaEl = (
      <video
        ref={vidRef}
        poster={posts[idx].sizes.medium.mediaUrl}
        src={posts[idx].mediaUrl}
        muted={false}
        autoPlay={false}
        loop={true}
      ></video>
    )
  }

  return (
    <div 

    className={`insta-panel ${(posts[idx].mediaType === "VIDEO") ? "video" : "image"}`}
    key={"panel"+posts[idx].id}
    
    onMouseEnter={() => {

      if(posts[idx].mediaType === "VIDEO"){
        setCursor('', false)
       // setPlaying(true)
       // if( vidRef.current){
       //   vidRef.current.play()
       //   vidRef.current.muted=false;
       // }
      }

      } }
      onMouseLeave={() => {
        if(posts[idx].mediaType === "VIDEO"){
          setCursor('', true)
        //  setPlaying(false)
        //  if( vidRef.current){
         //   vidRef.current.pause()
         //   vidRef.current.muted=true;
         // }
        }
      } }

      onClick={() => {

        

        if(posts[idx].mediaType === "VIDEO"){

          if(vidRef.current.paused){
            setCursor('', false)
            setPlaying(true)
            setInstaPlaying(idx)
            if( vidRef.current){
              vidRef.current.play()
              vidRef.current.muted=false;
            }
          }else{
            setCursor('', true)
            setPlaying(false)
            setInstaPlaying(null)
            if( vidRef.current){
              vidRef.current.pause()
              vidRef.current.muted=true;
            }
          }
        }
      } }
      
    >
    <figure key={posts[idx].id}>
      {mediaEl}
     {/* <figcaption>{post.caption}</figcaption> */}
     <div className="insta-icon"
     onClick={(e) => {

      e.preventDefault();

      console.log(posts[idx])

      window.open(posts[idx].permalink, "_blank")
      
     }}></div>
     <div className="insta-btn"></div>
    </figure>
    </div>
  )

}


const MessagePanel = ({idx, setCursor, ...props }) => {


  return (
    <div 

    className={`insta-panel message`}

    style={{
      height: 445+'px'
    }}
    key={"panelmsg"+idx}
    
    onMouseEnter={() => {

        setCursor('', false)
    

      } }
      onMouseLeave={() => {

          setCursor('', true)
     
      } }

      onClick={() => {

        

        window.open('https://www.instagram.com/waypastbooks/', "_blank")
      } }
      
    >
    <figure key={idx}>
      <h2>Click here to see more on Instagram at @waypastbooks</h2>

    </figure>
    </div>
  )

}