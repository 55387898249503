
const imgs =[


  {
    name: 'Book Song Challenge 2024 Flyer',
    description: '#a8edea → #fed6e3',
    thumb: "BookSong2024-thumb.jpg",
    type: "pdf",
    content: "/assets/images/pdf/BookSong2024-announcement.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },


  {
    name: 'Be A Way Past Kid',
    description: '#a8edea → #fed6e3',
    thumb: "pdf/GreatBigFeelings_BeAWayPastKid-thumb.jpg",
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/11/GreatBigFeelings_BeAWayPastKid_November23.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  
  {
    name: 'Interactive Game: Mixed Emotions',
    description: '#a8edea → #fed6e3',
    thumb: "mixed-poster.png", 
    type: "game",
    content: "https://mixed-emotions.waypastbooks.com", 
    ratio: 'horiz',
    height: 640,
    width: 400,
   
  },
  {
    name: 'Mixed Emotions Card Games',
    description: '#a8edea → #fed6e3',
    thumb: "WayPastBooks_MixedEmotions_Printable_Card_Deck.jpg",  
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/01/WayPastBooks_MixedEmotions_Printable_Card_Deck.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },

  {
    name: 'Way Past Mean Me Tee Activity',
    description: '#a8edea → #fed6e3',
    thumb: "WayPastMean_MyMeTeeWorksheet-thumb.jpg",
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2024/05/WayPastMean_MyMeTeeWorksheet.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },

  {
    name: 'Way Past Mean Cone of Caring Activity',
    description: '#a8edea → #fed6e3',
    thumb: "WayPastMean_ConeOfCaringWorksheet-thumb.jpg",
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2024/05/WayPastMean_ConeOfCaringWorksheet.pdf",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },




  {
    name: 'Way Past Mean Take A Shot Activity',
    description: '#a8edea → #fed6e3',
    thumb: "pdf/WayPastMean_BasketballActivitySheet-thumb.jpg",
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/11/WayPastMean_BasketballActivitySheet.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Embarrassed? Call for Help',
    description: '#a8edea → #fed6e3',
    thumb: "pdf/WayPastEmbarrassed_MakeACallWorksheet-thumb.jpg",
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/WayPastEmbarrassed_MakeACallWorksheet.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
 
  {
    name: 'Way Past Sorry Bubbles Worksheet',
    description: '#a8edea → #fed6e3',
    thumb: "pdf/WayPastSorry_Bubbles2-thumb.jpg",
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/WayPastSorry_Bubbles_worksheet.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },

  {
    name: 'Way Past Sorry? Make It Great!',
    description: '#a8edea → #fed6e3',
    thumb: "wpsorry-worksheet-thumb.jpg",
    type: "pdf",
    content: "/assets/images/pdf/WayPastSorry_MakeItGreatworksheet_September2023.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },

  {
    name: "Poster: Show You're Sorry",
    description: '#a8edea → #fed6e3',
    thumb: "WayPastSorry-Poster-thumb.jpg",
    type: "pdf",
    content: "/assets/images/pdf/WayPastSorry-Poster.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Book Song Challenge 2023 Winners',
    description: '#a8edea → #fed6e3',
    thumb: "BSC23_Winners-Thumbnail.jpg",
    type: "video",
    content: "https://youtu.be/YIb6T3A2E64",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Book Song Challenge 2023 Flyer',
    description: '#a8edea → #fed6e3',
    thumb: "BookSong2023-thumb.jpg",
    type: "pdf",
    content: "/assets/images/pdf/BSC23_AnnouncementFlyer.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Afraid Storm Cookie Recipe',
    description: '#a8edea → #fed6e3',
    thumb: "StormCookieRecipe_thumb.jpg",
    type: "pdf",
    content: "/assets/images/pdf/WayPastAfraid_StormCookieRecipe_v2.pdf",
   
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Afraid Thunder Dance Coloring Sheet',
    description: '#a8edea → #fed6e3',
    thumb: "thunderdance-thumb2.jpg",
    type: "pdf",
    content: "/assets/images/pdf/ThunderDanceColoringSheet.pdf",
   
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Lonely A-MAZE-ING Me Activity',
    description: '#a8edea → #fed6e3',
    thumb: "pdf/WayPastLonely_amazingme_Activity2-thumb.jpg",
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2024/03/WayPastLonely_A-MAZE-ingMe_Activity.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Lonely Power Crown Activity',
    description: '#a8edea → #fed6e3',
    thumb: "PowerCrown-thumb2.jpg",
    type: "pdf",
    content: "/assets/images/pdf/PowerCrownActivity.pdf",
   
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Poster: You Are the Strongest Thing',
    description: '#a8edea → #fed6e3',
    thumb: "pdf/TheStrongestThingPoster2-thumb.jpg",
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/TheStrongestThingPoster.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Book Song Challenge 2022 Winners',
    description: '#a8edea → #fed6e3',
    thumb: "BSC22-highlights.jpg",
    type: "video",
    content: "https://youtu.be/cKQ35QO-udE",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Book Song Challenge 2022 Flyer',
    description: '#a8edea → #fed6e3',
    thumb: "BookSong2022-thumb.jpg",
    type: "pdf",
    content: "/assets/images/pdf/BSC22_AnnouncementFlyer02.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'See Past Sad Telescope Activity',
    description: '#a8edea → #fed6e3',
    thumb: "pdf/WayPastSad_TelescopeActivity-thumb.jpg",
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/WayPastSad_TelescopeActivity.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Sad? Draw Your Mood',
    description: '#a8edea → #fed6e3',
    thumb: "DrawYourMoodPoster.jpg",
    type: "pdf",
    content: "/assets/images/pdf/DrawYourMood_Worksheet.pdf",
   
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
   // name: 'Interactive Game: Draw Your Own Dogs Like Yaz & Debby From Way Past Jealous!',
    name: 'Way Past Jealous Interactive Game: Draw Your Own Dogs',
    description: '#a8edea → #fed6e3',
    thumb: "DrawYourOwnDogs-game.jpg",
    type: "pdf",
    content: "/game/drawdogs",
   
    ratio: 'vert',
    height: 640,
    width: 400,
  },
   {
    name: 'Way Past Jealous Draw Your Own Dogs Worksheet',
    description: '#a8edea → #fed6e3',
    thumb: "DrawYourOwnDogs.jpg",
    type: "pdf",
    
    content: "/assets/images/pdf/Way_Past_Jealous-DrawYourOwnDogs_Worksheet-Updated_2023.pdf",
 
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Jealous Star Shades Activity',
    description: '#a8edea → #fed6e3',
    thumb: "StarShadesPoster.jpg",
    type: "pdf",
    content: "/assets/images/pdf/StarShades_Worksheet.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Jealous? Draw The Star You Are',
    description: '#a8edea → #fed6e3',
    thumb: "DrawTheStarYouArePoster.jpg",
    type: "pdf",
    content: "/assets/images/pdf/JealousWorksheet_DrawTheStarYouAre.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Book Song Challenge 2021 Highlights',
    description: '#a8edea → #fed6e3',
    thumb: "BSC21-highlights.png",
    type: "video",
    content: "https://youtu.be/g-2EbmdzxLE",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  
  {
    name: 'Book Song Challenge 2021 Flyer',
    description: '#a8edea → #fed6e3',
    thumb: "BSC21_Flyer_v2_with_Abigail_white-poster.jpg",
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/12/BSC21_Flyer_v2-with-Abigail.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  
  {
    name: 'Way Past Fun Tie Dye Video',
    description: '#a8edea → #fed6e3',
    thumb: "tiedye-thumb.jpg",
    type: "video",
    content: "https://youtu.be/CHV8ni_qJl8",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },

  {
    name: 'Way Past Fun Tie Dye Patterns',
    description: '#a8edea → #fed6e3',
    thumb: "TieDyeWorksheet.jpg",
    type: "pdf",
    content: "/assets/images/pdf/TieDyeWorksheet.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Worried Drive Thru Book Launch Party',
    description: '#a8edea → #fed6e3',
    thumb: "wpw-booklaunch.jpg",
    type: "video",
    content: "https://youtu.be/Vwj5iRifCWM",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Worry Whip Recipe Video',
    description: '#a8edea → #fed6e3',
    thumb: "worrywhip-thumb.jpg",
    type: "video",
    content: "https://youtu.be/6aIoYWQB0T0",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Worry Whip Recipe Sheet',
    description: '#a8edea → #fed6e3',
    thumb: "WorryWhipRecipeSheet.jpg",
    type: "pdf",
    content: "/assets/images/pdf/WorryWhipRecipeSheet.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Poster: Power Poses',
    description: '#a8edea → #fed6e3',
    thumb: "PowerPosesPoster.jpg",
    type: "pdf",
    content: "/assets/images/pdf/PowerPoses_poster.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Worried Power Poses Coloring Sheet',
    description: '#a8edea → #fed6e3',
    thumb: "PowerPosesColoring.jpg",
    type: "pdf",
    content: "/assets/images/pdf/PowerPoses_coloring.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: "Saul's Way Past Worried Book Review",
    description: '#a8edea → #fed6e3',
    thumb: "wpw-saulreview.jpg",
    type: "video",
    content: "https://youtu.be/nKMqPRh8k5I",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: "Sage's Way Past Worried Book Review",
    description: '#a8edea → #fed6e3',
    thumb: "wpw-sagereview.jpg",
    type: "video",
    content: "https://youtu.be/VNmM_yI5a4o",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: "London's Way Past Worried Book Review",
    description: '#a8edea → #fed6e3',
    thumb: "wpw-londonreview.jpg",
    type: "video",
    content: "https://youtu.be/p4CAAoa3xm4",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: "Abigail's Way Past Worried Book Review",
    description: '#a8edea → #fed6e3',
    thumb: "wpw-abigailreview.jpg",
    type: "video",
    content: "https://youtu.be/NPPIDzNf2es",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Book Review Worksheet',
    description: '#a8edea → #fed6e3',
    thumb: "BookReview_worksheet.jpg",
    type: "pdf",
    content: "/assets/images/pdf/BookReview_worksheet.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Mood Story Writing Excercise',
    description: '#a8edea → #fed6e3',
    thumb: "MoodStory_beginning_worksheet.jpg",
    type: "pdf",
    content: "/assets/images/pdf/MoodStory_beginning_worksheet.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  
   {
    name: 'Book Song Challenge 2020 Flyer',
    description: '#a8edea → #fed6e3',
    thumb: "BSC20_Flyer-poster.jpg",  
    type: "pdf",
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/12/BSC-2020-Flyer.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  
  {
    name: 'Stuck at Home #7: Sing a Song Like the 76ers Sixth Man',
    description: '#a8edea → #fed6e3',
    thumb: "Stuck-at-Home-07.jpg",
    type: "video",
    content: "https://youtu.be/y4Wgahb0ysA",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Build A Quiet Ship Worksheet',
    description: '#a8edea → #fed6e3',
    thumb: "Quiet-Ship-Checklist.jpg",
    type: "pdf",
    content: "/assets/images/pdf/Quiet-Ship-Checklist.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: "Stuck at Home #6: Build A Quiet Ship",
    description: '#a8edea → #fed6e3',
    thumb: "Stuck-at-Home-06.jpg",
    type: "video",
    content: "https://youtu.be/Rpvj-3my8jM",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Plan A Party Worksheet',
    description: '#a8edea → #fed6e3',
    thumb: "party-planning.jpg",
    type: "pdf",
    content: "/assets/images/pdf/party-planning.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Stuck at Home #5: Plan a Party',
    description: '#a8edea → #fed6e3',
    thumb: "Stuck-at-Home-05.jpg",
    type: "video",
    content: "https://youtu.be/On6QGqwK7Vo",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Stuck at Home #4: Make A Mad Cake',
    description: '#a8edea → #fed6e3',
    thumb: "Stuck-at-Home-04.jpg",
    type: "video",
    content: "https://youtu.be/ZXXAS_onTgY",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  
  {
    name: 'Way Past Fun Card Games',
    description: '#a8edea → #fed6e3',
    thumb: "waypastfun-cards-printable.jpg",
    type: "pdf",
    content: "/assets/images/pdf/cards-v6.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Stuck at Home #3: Play a Card Game',
    description: '#a8edea → #fed6e3',
    thumb: "Stuck-at-Home-03.jpg",
    type: "video",
    content: "https://youtu.be/aL8A-IsaL6M",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Stuck at Home #2: Create A Pet Game',
    description: '#a8edea → #fed6e3',
    thumb: "Stuck-at-Home-02.jpg",  
    type: "video",
    content: "https://youtu.be/T0A32oBnL1U",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Thank A Helper Worksheet',
    description: '#a8edea → #fed6e3',
    thumb: "thank-a-helper.jpg",
    type: "pdf",
    content: "/assets/images/pdf/thank-a-helper.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Stuck at Home #1: Thank A Helper',
    description: '#a8edea → #fed6e3',
    thumb: "Stuck-at-Home-01.jpg",
    type: "video",
    content: "https://youtu.be/4KP6Q588tRg",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
/*
///// REMOVE THIS ONE...
   {
    name: 'Mad Cake Challenge',
    description: '#a8edea → #fed6e3',
    thumb: "MadCakeChallenge01.jpg",
    type: "pdf",
    content: "/assets/images/pdf/MadCakeChallenge.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  */
   {
     name: 'Mad Cake Recipe Sheet',
     description: '#a8edea → #fed6e3',
     thumb: "MadCake-RecipeActivitySheet01.jpg",
     type: "pdf",
     content: "/assets/images/pdf/MadCake-RecipeActivitySheet01.pdf",
     ratio: 'vert',
     height: 640,
     width: 400,
   },
   
    {
    name: "Mad Cake Recipe Video",
    description: '#a8edea → #fed6e3',
    thumb: "MadCakesRecipe-Thumbnail.jpg", 
    type: "video",
    content: "https://youtu.be/OW9tTd-nEAM", 
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  
  {
    name: 'Way Past Mad Dance with Jordyn and Hara',
    description: '#a8edea → #fed6e3',
    thumb: "waypastmaddance.jpg",
    type: "video",
    content: "https://youtu.be/w2h3t-NqHto",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Mad Song Lyrics',
    description: '#a8edea → #fed6e3',
    thumb: "WayPastMad-song.jpg",
    type: "pdf",
    content: "/assets/images/pdf/WayPastMad-song.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: 'Way Past Mad Make-A-Face Activity',
    description: '#a8edea → #fed6e3',
    thumb: "WayPastMad-faces.jpg",
    type: "pdf",
    content: "/assets/images/pdf/WayPastMad-faces.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: "Saul's Tips for How to Get Past Your Mad",
    description: '#a8edea → #fed6e3',
    thumb: "saul-waypastmad-tips.png",
    type: "video",
    content: "https://youtu.be/iqEkNAunt9o",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: "Saul's Way Past Mad Book Review",
    description: '#a8edea → #fed6e3',
    thumb: "saul-waypastmad-review.png",
    type: "video",
    content: "https://youtu.be/SPz4n5Eson4",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: "Abigial's Way Past Mad Activity Idea",
    description: '#a8edea → #fed6e3',
    thumb: "Abigail-Activity.png",
    type: "video",
    content: "https://youtu.be/ObVDR_neAc8",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: "Abigail's Tips for How to Get Past Your Mad",
    description: '#a8edea → #fed6e3',
    thumb: "Abigail-Tips.png",
    type: "video",
    content: "https://youtu.be/1qr9WwxR72k",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  {
    name: 'The Penny Wish Writing Exercise',
    description: '#a8edea → #fed6e3',
    thumb: "MyPennyWish_worksheet.jpg",
    type: "pdf",
    content: "/assets/images/pdf/MyPennyWish_worksheet.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  {
    name: "Story Series Writing Tips: The Penny Wish",
    description: '#a8edea → #fed6e3',
    thumb: "StorySeries-01.jpg",
    type: "video",
    content: "https://youtu.be/GffDA4XJQ-k",
    ratio: 'horiz',
    height: 640,
    width: 400,
  },
  
 {
    name: 'Draw Your Own Quiet Ship',
    description: '#a8edea → #fed6e3',
    thumb: "MyQuietShip_DrawAQuietShip-600.jpg",
    type: "pdf",  
    content: "https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/12/MyQuietShip_DrawYourOwnQuietShip_01.pdf",
    ratio: 'vert',
    height: 640,
    width: 400,
  },
  









   
 

  

 
  
   

 
   


  
 
 

   

]

export default imgs;