import { render } from 'react-dom'
import React, { useState, useCallback, useEffect } from 'react'
import { useTransition, animated } from '@react-spring/web'
//import './styles.css'
import {
  useAudioStore,
  useSceneStore,
} from '../store'


const WPMean = ({setHover}) => {
  //function WPWorried(){
  return (
    <>
      <h2 className="mobile-title">Way Past Mean</h2>
      <h2 className="availability">Available in Store and Online</h2>

      <p>
      Once Ruby starts acting mean to the new girl, Nelly, she can’t stop. Yaz and Keya think it's funny, so Ruby acts way past mean, the kind of mean that's like a roller coaster she can't get off. Nelly's feelings are very hurt. Will Ruby make things right with Nelly? It's up to her to stop the ride.
      </p>
       <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Today!</h4>
          <div className="buylink-group" id="buylinks" 
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
            <li className="buylink">
                <a className="link" href="https://www.amazon.com/Way-Past-Mean-GREAT-FEELINGSTM/dp/080758021X/ref=sr_1_1?crid=13YT1RGZNKI2B&keywords=9780807580219&qid=1692035071&sprefix=9780807580219%2Caps%2C85&sr=8-1" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              {/*
              <li className="buylink">
                <a className="link" href="https://www.target.com/p/way-past-embarrassed-great-big-feelings-tm-by-hallee-adelman-hardcover/-/A-88928322#lnk=sametab" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
  */}
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/way-past-mean-hallee-adelman/1143852748?ean=9780807580219" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a href="https://bookshop.org/p/books/way-past-mean-hallee-adelman/20303875" target="_blank">
                  <img 
                  src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/03/bookshop.jpg" 
                  alt="Bookshop"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/Way-Past-Mean/Hallee-Adelman/9780807580219?id=8920299704910" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
       
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/book/way-past-mean/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
           
             
          
            </ul>
            
          </div>
         
        </div>
        
      </div>
      
    </>
  )
}



const WPEmbarrassed = ({setHover}) => {
  //function WPWorried(){
  return (
    <>
      <h2 className="mobile-title">Way Past Embarrassed</h2>
      <h2 className="availability">Available in Store and Online</h2>

      <p>
      Juan is having a hard time with a math worksheet while everyone else in his class seems to breeze through it. When the teacher calls on Juan to put answers on the board, he acts silly and even leaves the room. But after a mix-up reveals that he’s not the only one who struggles with math, Juan gains the courage to ask for help and inspires his classmates to do the same.
      </p>
       <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Today!</h4>
          <div className="buylink-group" id="buylinks" 
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
            <li className="buylink">
                <a className="link" href="https://www.amazon.com/Way-Past-Embarrassed-GREAT-FEELINGSTM/dp/0807580988" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.target.com/p/way-past-embarrassed-great-big-feelings-tm-by-hallee-adelman-hardcover/-/A-88928322#lnk=sametab" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/way-past-embarrassed-hallee-adelman/1143072587?ean=9780807580981" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a href="https://bookshop.org/p/books/way-past-embarrassed-hallee-adelman/19750458?ean=9780807580981" target="_blank">
                  <img 
                  src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/03/bookshop.jpg" 
                  alt="Bookshop"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/Way-Past-Embarrassed/Hallee-Adelman/9780807580981?id=8896290925614" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
       
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/book/way-past-embarrassed/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
           
             
          
            </ul>
            
          </div>
         
        </div>
        
      </div>
      
    </>
  )
}


const WPSorry = ({setHover}) => {
  //function WPWorried(){
  return (
    <>
      <h2 className="mobile-title">Way Past Sorry</h2>
      <h2 className="availability">Available in Store and Online</h2>

      <p>
      Kat promised to be Sage’s buddy on the class trip, but when it was time to board the bus, Kat sat with someone else. Now Sage is ignoring Kat, and won’t talk to her even after she apologizes. Kat is way past sorry. Somehow, she needs to make this right again with her best friend.</p>
      <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Today!</h4>
          <div className="buylink-group" id="buylinks" 
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
            <li className="buylink">
                <a className="link" href="https://www.amazon.com/Way-Past-Sorry-Great-Feelings/dp/0807581054" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.target.com/p/way-past-sorry-great-big-feelings-by-hallee-adelman-hardcover/-/A-88396604" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/way-past-sorry-hallee-adelman/1142036023" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a href="https://bookshop.org/p/books/way-past-sorry-hallee-adelman/18778846?ean=9780807581056" target="_blank">
                  <img 
                  src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/03/bookshop.jpg" 
                  alt="Bookshop"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/Way-Past-Sorry/Hallee-Adelman/9780807581056?__cf_chl_tk=DMd9Q.4ePeshQ.aYIpMwJJjCsK0_eaOE46pxIMEoKak-1679479658-0-gaNycGzNCrs" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
             
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/book/way-past-sorry/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
           
             
          
            </ul>
            
          </div>
         
        </div>
        
      </div>
      
    </>
  )
}


const WPLonely = ({setHover}) => {
  //function WPWorried(){
  return (
    <>
      <h2 className="mobile-title">Way Past Lonely</h2>
      <h2 className="availability">Available in Store and Online</h2>

      <p>
      Macy is lonely. Way past lonely. It’s a weekend. Two whole days. Dez is away, Keya and Hooper can’t play. Her mom is busy and her brother Luke doesn’t need her help. Even Princess Paws rushes away. What will Macy do?</p>
      <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Now!</h4>
          <div className="buylink-group" id="buylinks" 
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
            <li className="buylink">
                <a className="link" href="https://www.amazon.com/Way-Past-Lonely-Great-Feelings/dp/0807586722/ref=sr_1_1?dchild=1&keywords=9780807586723&qid=1632416815&sr=8-1" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/way-past-lonely-hallee-adelman/1140014461?ean=9780807586723" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/Way-Past-Lonely/Hallee-Adelman/9780807586723?id=8306084943085" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
             
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/book/way-post-lonely/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
           
              <li className="buylink">
                <a className="link" href="https://www.target.com/p/way-past-lonely-great-big-feelings-by-hallee-adelman-hardcover/-/A-85258148" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
          
            </ul>
          </div>
        </div>
      </div>
      <p>Now Available in Paperback</p>
    </>
  )
}

const WPAfraid= ({setHover}) => {

  return (
    <>
      <h2 className="mobile-title">Way Past Afraid</h2>
      <h2 className="availability">Available in Store and Online</h2>

      <p>
      Van is afraid. Way past afraid. Abbi and Van are having a sleepover at Grammy and Pop’s. Should be fun! But there’s a storm, with loud thunder and bursts of lightning. The lights go out. Van is way past scared. What can make him feel better? </p>
      <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Now!</h4>
          <div className="buylink-group" id="buylinks" 
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
            <li className="buylink">
                <a className="link" href="https://www.amazon.com/Way-Past-Afraid-Great-Feelings/dp/0807586749/ref=sr_1_1?dchild=1&keywords=9780807586747&qid=1632416754&sr=8-1" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/way-past-afraid-hallee-adelman/1140014466?ean=9780807586747" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/Way-Past-Afraid/Hallee-Adelman/9780807586747?id=8306084943085" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
            
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/book/way-past-afraid/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
            
              <li className="buylink">
                <a className="link" href="https://www.target.com/p/way-past-afraid-great-big-feelings-by-hallee-adelman-hardcover/-/A-85258149" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
        
            </ul>
          </div>
        </div>
      </div>
      <p>Now Available in Paperback</p>
    </>
  )
}

const WPWorried = ({setHover}) => {
  //function WPWorried(){
  return (
    <>
      <h2 className="mobile-title">Way Past Worried</h2>
      <h2 className="availability">Available in Store and Online</h2>

      <p>
      Brock is worried. Way past worried, with his heart thumping and his mind racing. Today is his friend Juan’s superhero party and he’s going all by himself. What if nobody plays with him? What if everyone laughs at him? Brock doesn’t feel like a superhero, but… what if he can save the day and find a way past worried all by himself? This engaging story speaks to kids’ emerging emotional intelligence skills and helps them learn to manage worry.
      </p>
      <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Today!</h4>
          <div className="buylink-group" id="buylinks" 
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
            <li className="buylink">
                <a className="link" href="https://www.amazon.com/Way-Past-Worried-Great-Feelings/dp/0807586862/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/way-past-worried-hallee-adelman/1136487427" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/Way-Past-Worried/Hallee-Adelman/9780807586860" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
             
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/product/way-past-worried-hardcover/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.target.com/p/way-past-worried-great-big-feelings-by-hallee-adelman-hardcover/-/A-80553287" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p>Also Available in Paperback</p>
    </>
  )
}

const WPMad = ({setHover}) => {
  return (
    <>
      <h2 className="mobile-title">Way Past Mad</h2>
      <h2 className="availability">Available in Store and Online</h2>

      <p>
        Keya is way past mad. Her little brother Nate messed up everything–even breakfast. She heads to school kicking
        rocks and sticks. When her best friend Hooper tries to help, Keya shouts, “I don’t even like you.” It’s not
        true, but Hooper storms off, kicking rocks and sticks too. Keya gave him her mad! Now it’s up to Keya to find a
        different way past mad and to make things right. A relatable story that speaks to kids’ emerging emotional
        intelligence skills.
      </p>
      <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Today!</h4>
          <div className="buylink-group" id="buylinks"
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
              <li className="buylink">
                <a className="link" href="https://www.amazon.com/Way-Past-Mad-Hallee-Adelman/dp/0807586854" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/way-past-mad-hallee-adelman/1133989117" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/Way-Past-Mad/Hallee-Adelman/9780807586853?id=7720289072889" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
             
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/book/way-past-mad/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.target.com/p/way-past-mad-by-hallee-adelman-hardcover/-/A-79489555" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p>Also Available in Paperback</p>
    </>
  )
}

const WPSad = ({setHover}) => {
  return (
    <>
      <h2 className="mobile-title">Way Past Sad</h2>
      <h2 className="availability">Available in Store and Online</h2>
      <p>
        James is sad. Way past sad. His best friend, Sanj, is moving away. James feels all alone, and even hugs from Mom
        don’t take away all his sad. But it helps to talk about it. Nothing can change the fact that Sanj is moving, but
        will James and Sanj always be sad? Or is there a way to get past it?
      </p>
      <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Now!</h4>
          <div className="buylink-group" id="buylinks"
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
              <li className="buylink">
                <a className="link" href="https://www.amazon.com/Way-Past-Sad-Great-Feelings/dp/080758679X/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/way-past-sad-hallee-adelman/1137286817" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/Way-Past-Sad/Hallee-Adelman/9780807586792" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
            
              
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
      
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/book/way-past-sad/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
       
              <li className="buylink">
                <a className="link" href="https://www.target.com/p/way-past-sad-great-big-feelings-by-hallee-adelman-hardcover/-/A-81484155" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p>Now Available in Paperback</p>
    </>
  )
}

const WPJealous = ({setHover}) => {
  return (
    <>
      <h2 className="mobile-title">Way Past Jealous</h2>
      <h2 className="availability">Available in Store and Online</h2>
      <p>
      Yaz is jealous. Way past jealous. Yaz loves to draw, but no one ever notices her pictures. Everyone loves Debby’s drawings, and one even got put up on the classroom wall with a star on it. Now Yaz’s jealousy is making her think ugly things, and even act mean! How can she get past jealous?
      </p>
      <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Now!</h4>
          <div className="buylink-group" id="buylinks"
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
              <li className="buylink">
                <a className="link" href="https://www.amazon.com/Way-Past-Jealous-Great-Feelings/dp/0807586781" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/way-past-jealous-hallee-adelman/1137286824" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/Way-Past-Jealous/Hallee-Adelman/9780807586785?id=8001995183770#" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
            
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
           
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/book/way-past-jealous/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
           
               <li className="buylink">
                <a className="link" href="https://www.target.com/p/way-past-jealous-great-big-feelings-by-hallee-adelman-hardcover/-/A-81484178" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p>Now Available in Paperback</p>
    </>
  )
}
/*
const pages = [

  ({ style, setHover }) => <animated.div style={{ ...style}}>{WPMad({setHover})}</animated.div>,
  ({ style, setHover }) => <animated.div style={{ ...style}}>{WPWorried({setHover})}</animated.div>,
  ({ style, setHover }) => <animated.div style={{ ...style}}>{WPSad({setHover})}</animated.div>,
  ({ style, setHover}) => <animated.div style={{ ...style}}>{WPJealous({setHover})}</animated.div>,
  ({ style, setHover }) => <animated.div style={{ ...style}}>{WPLonely({setHover})}</animated.div>,
  ({ style, setHover}) => <animated.div style={{ ...style}}>{WPAfraid({setHover})}</animated.div>,
]
*/
/*
const pages = [
        
  <WPMad />,
  <WPWorried />,
  <WPSad />,
  <WPJealous />,
  <WPLonely/>,
  <WPAfraid />,
      

]
*/

const pages = [

  ({ style, setHover }) => <animated.div style={{ ...style}}><WPMad setHover={setHover}/></animated.div>,
  ({ style, setHover }) => <animated.div style={{ ...style}}><WPWorried setHover={setHover}/></animated.div>,
  ({ style, setHover }) => <animated.div style={{ ...style}}><WPSad setHover={setHover}/></animated.div>,
  ({ style, setHover}) => <animated.div style={{ ...style}}><WPJealous setHover={setHover}/></animated.div>,
  ({ style, setHover }) => <animated.div style={{ ...style}}><WPLonely setHover={setHover}/></animated.div>,
  ({ style, setHover}) => <animated.div style={{ ...style}}><WPAfraid setHover={setHover}/></animated.div>,
  ({ style, setHover}) => <animated.div style={{ ...style}}><WPSorry setHover={setHover}/></animated.div>,
  ({ style, setHover}) => <animated.div style={{ ...style}}><WPEmbarrassed setHover={setHover}/></animated.div>,
  ({ style, setHover}) => <animated.div style={{ ...style}}><WPMean setHover={setHover}/></animated.div>,

]

export default function Slides() {

  const { cursorActive, bookIndex  } = useSceneStore(state => state)
  const { setCursorActive } = useSceneStore(state => state.actions)
  
  
  const [index, set] = useState(bookIndex)


  //const onClick = useCallback(() => set((state) => (state + 1) % 6), [])
 // const transitions = useTransition(index, (p) => p, {
 // const transitions = useTransition(pages[bookIndex], {
  const transitions = useTransition(index, {
    keys: index,
    from: { opacity: 0.5 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    exitBeforeEnter: true
  })

  useEffect(() => {
    console.log('slides: index:'+index+' , bookindex:'+bookIndex)
   
    // if (bookIndex<= pages.length ) {
     if (bookIndex < 9 ) { //book total + 1
         set(bookIndex)
     }else{
        set(0)
     }
     
  
   }, [bookIndex])

   function setHover(boo){
     console.log('boo is '+boo)
      setCursorActive(boo);
   }

   function Page(){
     return 
   }


  return (
    <div className="book-description" 
     // onMouseEnter={() => setHover('', false)} 
     // onMouseLeave={() => setHover('', true)} 
    >
      {transitions((styles, i) => {
       const Page = pages[i]
        return <Page key={i+'pg'} style={styles} setHover={(e) => setHover(e)} />
     }
     
     )}



        {/*transitions((style, item, idx) => (
            <animated.div style={style}>{idx}</animated.div>
          ))
        */}
 
     {/* transitions((props, idx, key) => {
       const Page = pages[idx]
        return <Page key={key} style={props} setHover={(e) => setHover(e)} />
     })
    */}
    </div>
  )
}
